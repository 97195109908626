<template>
  <!-- 配置模板 -->
  <a-breadcrumb>
    <a-breadcrumb-item>内容管理</a-breadcrumb-item>
    <a-breadcrumb-item>{{ props.name }}</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="content">
    <div class="action">
      <a-checkbox v-model:checked="checked" @change="changeChecked">隐藏停用{{ props.shortName }}</a-checkbox>
      <a-button type="primary" @click="add"><plus-outlined />新增{{ props.shortName }}</a-button>
    </div>
    <div class="table-box">
      <a-table
        :customRow="customRow"
        :data-source="dataSource"
        :columns="columns"
        :pagination="pagination"
        :row-key="record => record.id"
        :loading="isLoading"
        @change="handleTableChange"
      >
        <template #bodyCell="{ column, record }">
          <!-- 名称编辑组件 -->
          <template v-if="column.key === 'name'">
            <Edit :source="source" :data="record" :editData="editData" @editStatus="editStatus" @editLoading="editLoading"></Edit>
          </template>
          <!-- 操作 -->
          <template v-if="column.key === 'operation'">
            <a class="operation-item" @click="move(record, 'up')">上移</a>
            <a class="operation-item" @click="move(record, 'down')">下移</a>
            <a class="operation-item" @click="stop(record)">{{ record.status === 1 ? '停用' : '开启'}}</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
  <!-- 新增、编辑弹窗 -->
  <Add ref="RefAdd" :source="source" @success="getData" />
</template>

<script setup>
import { ref, reactive, nextTick, onBeforeMount } from 'vue'
import { message } from 'ant-design-vue'
import { ClassifySettings } from '@/utils/constantList'
import Add from './Add.vue'
import Edit from './Edit'
import { getAllClassifyList, allClassifySort, allClassifySwitch } from '@/api/system'

// props（source：请同步维护 constantList 文件中 ClassifySettings 字段）
const props = defineProps({
  name: String,
  shortName: String,
  source: String
})
// 配置类型
const source = ClassifySettings[props.source]

// 单个子组件暴露体初始化
let RefAdd = ref(null)
// 是否隐藏停用类型
let checked = ref(true)
// 数据源
let dataSource = ref(null)
// 加载
let isLoading = ref(false)
// 表头
let columns = reactive([
  {
    title: '顺序',
    dataIndex: 'sord_id',
    key: 'sord_id',
  },
  {
    title: props.name,
    dataIndex: 'name',
    key: 'name',
    width: 500
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])
// 分页
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 名称编辑中
let isEdit = ref(false)
// 编辑中的数据
let editData = ref({})

// 钩子函数 - onBeforeMount
onBeforeMount(() => {
  getData()
})

// 表格change
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getData()
}

// 切换隐藏状态
function changeChecked () {
  pagination.current = 1
  // 获取列表
  getData()
}

// 请求分页数据
function getData () {
  isLoading.value = true
  const params = {
    source,
    status: checked.value ? 1 : 0,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  getAllClassifyList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data.list
      pagination.total = data.paginate.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 子组件新增弹窗
function add () {
  nextTick(() => {
    RefAdd.value.showModal() //调用子组件的方法
  })
}

// 上移、下移
function move (record, type) {
  isLoading.value = true
  allClassifySort({
    source,
    type,
    id: record.id
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getData()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 开启停用
function stop (record) {
  isLoading.value = true
  allClassifySwitch({
    source,
    type: record.status === 1 ? 0 : 1,
    id: record.id
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getData()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 列表自定义Row
function customRow (record) {
  return ({
    onMouseenter () {
      if (isEdit.value) { return }
      editData.value = record
    },
    onMouseleave () {
      if (isEdit.value) { return }
      editData.value = {}
    }
  })
}

// 编辑状态变更
// （注意：vue2版本子组件携带过来的直接就是ref的value，不需要通过.value赋值）
function editStatus (isEdit) {
  isEdit.value = isedit
  if (!isEdit) {
    editData.value = {}
  }
}

// 编辑修改进度
// （注意：vue2版本子组件携带过来的直接就是ref的value，不需要通过.value赋值）
function editLoading (isLoading) {
  isLoading = isLoading
}

</script>

<style lang="less" scoped>
.content {
  padding: 24px 0;
  .action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .operation-item {
    margin-right: 8px;
  }
}

</style>