<template>
  <a-modal
    title="新建"
    :visible="visible"
    :confirm-loading="isLoading"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <!-- 表单数据 -->
    <a-form
      name="form"
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 表单项 -->
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true, message: '请输入名称' }]"
      >
        <a-input v-model:value="formState.name" />
      </a-form-item>
      <!-- 表单项 -->
      <a-form-item
        label="顺序"
        name="sort"
        :rules="[{ required: true, message: '请输入顺序' }]"
      >
        <a-input v-model:value="formState.sort" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { allClassifyAdd } from '@/api/system'

// 注册父组件的事件
const emit = defineEmits('success')
const props = defineProps({
  source: String
})
// 表单实例
let RefForm = ref(null)
// 是否展示弹窗
let visible = ref(false)
// 表单数据
let formState = reactive({
  name: undefined,
  sort: undefined
})
// 加载
let isLoading = ref(false)

// 展示弹窗
function showModal () {
  visible.value = true
}

// 关闭弹窗
function handleCancel () {
  // 重置表单
  RefForm.value.resetFields()
  visible.value = false
}

// 提交
function handleOk () {
  // 手动触发表单验证
  RefForm.value.validate()
  .then(() => {
    isLoading.value = true
    allClassifyAdd({
      source: props.source,
      name: formState.name,
      sort: formState.sort
    }).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        // 更新父组件
        emit('success')
        // 关闭弹窗
        handleCancel()
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

defineExpose({
  showModal
})

</script>

<style lang="less" scoped>

</style>