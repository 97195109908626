<template>
  <div>
    <!-- 内容 -->
    <div style="white-space: nowrap;" v-show="!isEdit || editData.id !== data.id">
      {{ data.name }}
      <edit-outlined v-show="editData.id === data.id" type="edit" @click="startEdit" />
    </div>
    <!-- 编辑内容 -->
    <div style="white-space: nowrap;" v-show="isEdit && editData.id === data.id">
      <a-input
        :value="value"
        @change="onChange"
        style="width: 150px; margin-right: 5px;"
      />
      <a class="edit-button" @click="editSave">保存</a>
      <a class="edit-button" @click="editCancel">取消</a>
    </div>
  </div>
</template>

<script>
import Pub from '@/utils/public'
import { allClassifyUpdate } from '@/api/system'
export default {
  props: {
    source: {
      type: String,
      default: () => ''
    },
    data: {
      type: Object,
      default: () => {}
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isEdit: false,
      value: this.data.name
    }
  },
  watch: {
    editData (newData, oldData) {
      if (!newData.id && this.isEdit) {
        this.isEdit = false
      }
    }
  },
  methods: {
    // 编辑框内容变换
    onChange (e) {
      this.value = Pub.STRING_SPACE_ALL(e.target.value)
    },
    // 开始编辑
    startEdit () {
      this.isEdit = true
      this.$emit('editStatus', this.isEdit)
    },
    // 修改取消
    editCancel () {
      this.isEdit = false
      this.$emit('editStatus', this.isEdit)
    },
    // 修改保存提交
    editSave () {
      if (this.value.length === 0) {
        this.$message.error('名称不能为空')
        return
      }
      if (this.editData.name === this.value) {
        this.editCancel()
        return
      }
      this.$emit('editLoading', true)
      const params = {
        source: this.source,
        id: this.data.id,
        name: this.value
      }
      allClassifyUpdate(params).then(res => {
        this.$emit('editLoading', false)
        const { code, msg } = res
        if (code === 0) {
          this.editData.name = this.value
          this.editCancel()
          this.$message.success('修改成功')
        } else {
          this.$message.error(msg)
        }
      }).catch(err => {
        this.$emit('editLoading', false)
        this.$message.error(err)
      })
    }
  }
}
</script>

<style scoped>
.edit-button {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  color: #1890FF;
  padding: 0px 0px 0px 8px;
}
</style>
